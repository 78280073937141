"use strict"
 
/**
 *	----------------------------------------------------------------------------------------------
 *	Class ModuleLoader 
 * 	----------------------------------------------------------------------------------------------
 */
 class ModuleLoader{

	static async loadPage(pageData){
		const page = pageData.config.page;
		const Module = await import(/* webpackChunkName: "page" */ `../templates/pages/${page}/${page}.controller.js`).catch((err) => { console.error(err); });
		return Module;
	}

	static async loadView(pageData){
		const page = pageData.config.page;
		const view = pageData.config.view;
		const Module = await import(/* webpackChunkName: "view" */ `../templates/pages/${page}/views/${view}/${view}.controller.js`).catch((err) => { console.error(err); });
		return Module;
	}
}

export { ModuleLoader };