"use strict"
/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import AppSimpleLayout from "../templates/layouts/simple/simpleLayout.controller"

/**
 *	----------------------------------------------------------------------------------------------
 *	Array Routes
 * 	----------------------------------------------------------------------------------------------
 */
const routes = [
	{path: "/", 					config: {auth: false,	page: "start",			view: "", 				layout: null}},
	{path: "/start/gemeinde", 		config: {auth: false,	page: "start",			view: "gemeinde", 		layout: null}},
	{path: "/start/gemeinde/", 		config: {auth: false,	page: "start",			view: "gemeinde", 		layout: null}},
	{path: "/start/organisiert", 	config: {auth: false,	page: "start",			view: "organisiert", 	layout: null}},
	{path: "/start/organisiert/", 	config: {auth: false,	page: "start",			view: "organisiert", 	layout: null}},
	{path: "/start/funktionen", 	config: {auth: false,	page: "start",			view: "funktionen", 	layout: null}},
	{path: "/start/funktionen/", 	config: {auth: false,	page: "start",			view: "funktionen", 	layout: null}},
	{path: "/start/datenschutz", 	config: {auth: false,	page: "start",			view: "datenschutz", 	layout: null}},
	{path: "/start/datenschutz/", 	config: {auth: false,	page: "start",			view: "datenschutz", 	layout: null}},
	{path: "/start/kontakt", 		config: {auth: false,	page: "start",			view: "kontakt", 		layout: null}},
	{path: "/start/kontakt/", 		config: {auth: false,	page: "start",			view: "kontakt", 		layout: null}},
	{path: "/impressum", 			config: {auth: false,	page: "impressum",		view: "", 				layout: null}},
	{path: "/impressum/", 			config: {auth: false,	page: "impressum",		view: "", 				layout: null}},
	{path: "/datenschutz", 			config: {auth: false,	page: "datenschutz",	view: "", 				layout: null}},
	{path: "/datenschutz/", 		config: {auth: false,	page: "datenschutz",	view: "", 				layout: null}},
];

export default routes;