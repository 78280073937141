@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;

app-header {
	display: block;
	position: sticky;
	top: 0;
	z-index: 1000;
	transition: transform 0.2s ease-in-out;

	&.is-hidden {
		transform: translateY(-100%);
	}
}

.adm-header {
	@include adm.edge-padding();

	background: adm.color("white");

	.inner {
		@include adm.max-width();
		@include adm.grid(1fr auto);
		@include adm.grid-gutter();
		height: 8rem;
		align-items: center;

		@include adm.device("desktop") {
			@include adm.grid(auto 1fr auto);
			--adm-grid-gutter: 2rem;
		}

		@include adm.device("desktop-hd") {
			height: 12rem;
			//@include adm.grid(30rem 1fr auto);
			align-items: center;
		}
	}

	.jump-nav {
		//display: none;
		position: absolute;
		top: 8rem;
		left: 0;
		right: 0;

		a {
			display: none;
			background: adm.color("super-light-grey");
			padding: 2rem;
			width: 100%;
			text-decoration: none;
			font-weight: bold;
			pointer-events: none;
			&.active {
				display: block;
			}
		}

		@include adm.device("desktop") {
			display: flex;
			justify-content: center;
			position: static;
			left: auto;
			top: auto;
			right: auto;
			a {
				margin-right: adm.grid-gutter();
				padding: 1.2rem;
				position: relative;
				display: block;
				background: transparent;
				width: auto;
				pointer-events: all;

				&:hover,
				&:focus {
					color: adm.color("primary");
				}

				&.active {
					//color: adm.color("white");
					background: adm.color("light-grey");
					border-radius: 5px;
				}
			}
		}
	}
}
