"use strict"
/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import "./../sass/styles.scss";

import { AdmApp, AdmRouter, AdmI18n, AdmPager, AdmSlimTextPlugin } from "@minddraft/adamantium-frontend-app";
import AppBasicLayout from "../templates/layouts/basic/basicLayout.controller";
import { ModuleLoader } from "./loader";
import routes from "./routes";

const APP_CONFIG = process.env.APP_CONFIG;

/**
 *	----------------------------------------------------------------------------------------------
 *	Class Main-Application
 * 	----------------------------------------------------------------------------------------------
 */
document.addEventListener("DOMContentLoaded", () => {
	
	const app = new AdmApp({
		theme: 			'',				// CSS Klasse von Adamantium UI: setzt css Klasse im Body-Tag
		darkMode: 		false,			// Toogle hell oder dunkel Ansicht abhängig davon ob adamantium UI theme das unterstützt
		slimjs:			{textPlugin: AdmSlimTextPlugin},
	}); 
	
	/**
	 * 	---------------------------------------
	 * 	Register app modules
	 * 	---------------------------------------
	 */ 
	app.module("pager", AdmPager, {listen: true, appendTo: "#appMain", loader: ModuleLoader.loadPage, defaultLayout: AppBasicLayout});
	app.module("router", AdmRouter, {root: '', routes: routes, listen: true, path404: '/404'});

	app.init();
});
