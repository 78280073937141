"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
 import { Slim } from "slim-js";
 import { tag, template, useShadow } from 'slim-js/decorators';

import TEMPLATE_HTML from './simpleLayout.template.html';
import TEMPLATE_CSS from './simpleLayout.styles.scss';

/**
 *	----------------------------------------------------------------------------------------------
 *	Class AppSimpleLayout
 * 	----------------------------------------------------------------------------------------------
 */
@tag('app-simple-layout')
@template(`<style>${TEMPLATE_CSS}</style>${TEMPLATE_HTML}`)
@useShadow(false)
class AppSimpleLayout extends Slim {
    
    constructor(ready){
		super();
		this.onRender = ready;
	}
}

export default AppSimpleLayout;