"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
 import { Slim } from "slim-js";
 import { tag, template, useShadow } from 'slim-js/decorators';

import AppHeader from "../../components/header/header.controller";
import AppFooter from "../../components/footer/footer.controller"; 
import AppCookieMessage from "../../components/cookiemessage/cookiemessage.controller"; 

import TEMPLATE_HTML from './basicLayout.template.html';
import TEMPLATE_CSS from './basicLayout.styles.scss';

/**
 *	----------------------------------------------------------------------------------------------
 *	Class AppBasicLayout
 * 	----------------------------------------------------------------------------------------------
 */
@tag('app-basic-layout')
@template(`<style>${TEMPLATE_CSS}</style>${TEMPLATE_HTML}`)
@useShadow(false)
class AppBasicLayout extends Slim {
    
	setScrollbarWith() {
		const outer = document.createElement("div");
		outer.style.visibility = "hidden";
		outer.style.width = "100px";
		outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
		document.body.appendChild(outer);
		var widthNoScroll = outer.offsetWidth;
		// force scrollbars
		outer.style.overflow = "scroll";
		// add innerdiv
		var inner = document.createElement("div");
		inner.style.width = "100%";
		outer.appendChild(inner);
		var widthWithScroll = inner.offsetWidth;
		// remove divs
		outer.parentNode.removeChild(outer);

		document.querySelector(":root").style.setProperty('--scrollbar-width', widthNoScroll - widthWithScroll +"px");
	};

    constructor(ready){
		super();
		this.onRender = ready;
		this.setScrollbarWith();
	}
}

export default AppBasicLayout;