@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;

app-cookiemessage {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;

	.inner{
		background-color: #fff;
		padding: 3rem;
		position: absolute;
		text-align: center;

		@include adm.device("tablet") {
			max-width: 700px;
		}
	}

	&.hide{
		display: none;	
	}
}