"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import { Slim } from "slim-js";
import { tag, template, useShadow } from 'slim-js/decorators';
import 'slim-js/directives';

import {navTo} from "../../../js/utils";

import TEMPLATE_HTML from './header.template.html';
import TEMPLATE_CSS from './header.styles.scss';

/**
 *	----------------------------------------------------------------------------------------------
 *	Class HeaderComponent - Module
 * 	----------------------------------------------------------------------------------------------
 */
@tag('app-header')
@template(`<style>${TEMPLATE_CSS}</style>${TEMPLATE_HTML}`)
@useShadow(false)
class AppHeader extends Slim {
    show() {
		document.querySelector(this.headerSelector).classList.remove("is-hidden");
	}
	hide() {
		document.querySelector(this.headerSelector).classList.add("is-hidden");
	}
	sticky (state) {
		if (state == true) {
			document.querySelector(this.headerSelector).classList.remove("is-sticky")
		} else {
			document.querySelector(this.headerSelector).classList.add("is-sticky")
		}
	}
	gliding(state) {
		if (state == false) {
			document.querySelector(this.headerSelector).classList.remove("is-gliding")
		} else {
			document.querySelector(this.headerSelector).classList.add("is-gliding")
		}
	}
	

	onScroll() {
		this.currentScrollY = window.pageYOffset;

		if (!this.ticking) {
			requestAnimationFrame(this.update.bind(this));
		}
		this.ticking = true;
	}
	
	update() {
		return;
		if(this.currentScrollY > 0) {			
			// set the sticky / gliding classes
			if (this.currentScrollY > this.config.stickyOffset) {
				this.gliding();
			} else if (this.currentScrollY < this.config.stickyOffset) {
				this.gliding(false);
			}

			// set the autohide classes if autohide is true
			if(this.config.autoHide) {
				if (this.currentScrollY < this.config.autoHideOffset) {
					this.show();
				} else if (this.currentScrollY < this.lastScrollY) {
					this.show();
				} else if (this.currentScrollY > this.lastScrollY) {
					this.hide(); 
				}
			}
		} else {
			this.gliding(false);
		}
	
		this.lastScrollY = this.currentScrollY;
		this.ticking = false;
	}

    onCreated (){
		//console.log("AppHeader::onCreated");
	}
	onAdded(){
		//console.log("AppHeader::onAdded");
	}	
	onRender(){
		//console.log("AppHeader::onRender"); 
		if(this.config.sticky) {
			this.sticky();
			document.addEventListener("scroll", this.onScroll.bind(this), {passive: true});
		}
	}	 

	clickNav(evt){
		console.log("AppHeader::clickNav");
		evt.preventDefault();

	}

	constructor(){
		super();
		
		this.config = {
			// when true, header will be hidden when the autoHideOffset is reached
			autoHide: false,
			autoHideOffset: 300,
			// offset where the is-sticky class is set
			sticky: true,
			stickyOffset: 100, // value in px, when the is-gliding class is set
		};
		this.headerSelector = ".app-header";
		this.ticking = false;
		this.currentScrollY = 0;
		this.lastScrollY = 0;

		this.navTo = navTo;

	}
}

export default AppHeader