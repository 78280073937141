@use "/node_modules/@minddraft/adamantium-frontend-ui/lib/sass/core/core" as adm;

.adm-footer {
	@include adm.edge-padding();

	.inner {
		@include adm.max-width();
		padding-top: 2rem;
		padding-bottom: 2rem;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		@include adm.device("tablet") {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
		@include adm.device("desktop") {
		}

		.footer-copy {
			display: flex;
			justify-content: center;

			text-align: center;
		}
		.footer-nav {
			display: flex;
			justify-content: center;

			a {
				text-decoration: none;
				display: block;
				margin-left: calc(adm.grid-gutter() / 2);
				margin-right: calc(adm.grid-gutter() / 2);
				b {
					font-weight: 500;
					color: adm.color("primary");
				}
			}
		}
	}
}
