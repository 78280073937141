"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
import { Slim } from "slim-js";
import { tag, template, useShadow } from 'slim-js/decorators';
import 'slim-js/directives';

import TEMPLATE_HTML from './cookiemessage.template.html';
import TEMPLATE_CSS from './cookiemessage.styles.scss';

/**
 *	----------------------------------------------------------------------------------------------
 *	Class CookieMessageComponent - Module
 * 	----------------------------------------------------------------------------------------------
 */
@tag('app-cookiemessage')
@template(`<style>${TEMPLATE_CSS}</style>${TEMPLATE_HTML}`)
@useShadow(false)
class AppCookieMessage extends Slim {

	constructor(){
		super();

		this.cookiesAllowed = localStorage.getItem("app-cookies");
		if(this.cookiesAllowed){
			this.classList.add("hide");
		}
	}

	acceptCookies(){
		this.classList.add("hide");
		localStorage.setItem("app-cookies", true);
	}
}  

export default AppCookieMessage