"use strict"

import { eventBus} from "@minddraft/adamantium-frontend-app";

export const navTo = (event) => {
	console.log("navTo");
	event.preventDefault();
	const path = event.target.getAttribute("href");
	eventBus.dispatchEvent("navigate", {path});
};
