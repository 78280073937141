"use strict"

/**
 *	----------------------------------------------------------------------------------------------
 *	Imports
 * 	----------------------------------------------------------------------------------------------
 */
 import { Slim } from "slim-js";
 import { tag, template, useShadow } from 'slim-js/decorators';

import TEMPLATE_HTML from './footer.template.html';
import TEMPLATE_CSS from './footer.styles.scss';

/**
 *	----------------------------------------------------------------------------------------------
 *	Class FooterComponent - Module
 * 	----------------------------------------------------------------------------------------------
 */
@tag('app-footer')
@template(`<style>${TEMPLATE_CSS}</style>${TEMPLATE_HTML}`)
@useShadow(false)
class AppFooter extends Slim {

}

export default AppFooter